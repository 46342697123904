var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-navigation-drawer',{attrs:{"temporary":"","height":"100%","right":"","dark":"","app":""},model:{value:(_vm.sidebar),callback:function ($$v) {_vm.sidebar=$$v},expression:"sidebar"}},[_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{staticClass:"text-end pa-3 ma-2",attrs:{"icon":""},on:{"click":function($event){_vm.sidebar = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-list',{staticClass:"mt-0"},[(_vm.getAuth)?_c('v-list-item',[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account")])],1),_c('v-list-item-content',[_c('div',{staticClass:"text-body-1 text-break"},[_vm._v(_vm._s(_vm.getAuth))])])],1):_vm._e(),(_vm.getAuth)?_c('v-divider'):_vm._e(),_vm._l((_vm.menuItems),function(item){return _c('v-list-item',{key:item.title,attrs:{"to":{
        hash: item.path.includes('#') == false ? undefined : item.path,
        name: item.path.includes('#') == true ? 'home' : item.path,
      }}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_vm._v(_vm._s(item.title))])],1)})],2),_c('span',{staticClass:"d-flex justify-center hidden-md-and-up"},[(_vm.getAuth == null)?_c('v-btn',{staticClass:"ma-2",attrs:{"width":"80","outlined":"","rounded":"","to":{
        name: 'login',
      }}},[_vm._v("Login")]):_c('v-btn',{staticClass:"ma-2",attrs:{"width":"80","outlined":"","rounded":""},on:{"click":_vm.logout}},[_vm._v("Logout")]),_c('v-btn',{staticClass:"ma-2",attrs:{"width":"80","to":{
        name: 'home',
        hash: '#faq',
      },"outlined":"","rounded":""}},[_vm._v("FAQ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }